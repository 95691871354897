import React, { useEffect, useState } from "react";
import {
  Alert,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  Image,
  Row,
} from "react-bootstrap";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import {
  getCompanyDetail,
  getUserDetail,
  updateCompanyDetail,
} from "../../store/actions/users/users.actions";
import LoginModal from "../../components/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PlanifySubscriptionModal from "../../components/PlanifySubscriptionModal";
import { isPlanifySubscriptionActive } from "../../utils/helpers/helper";
import diversityLogo from "../../assets/img/diveristy.png";
const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

const GCPlanify = () => {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [error, setError] = useState(null);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [submissionError, setSubmissionError] = useState(null);

  const [selectedRegions, setSelectedRegions] = useState([]);
  const { companyDetail } = useSelector((state) => state.companyDetail);

  const initialState = {
    pre_qualification_url: "",
    plan_room_url: "",
    working_region: [], // Example for a multi-select field, initialize as an empty array
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    website: "",
    is_add_itbs: false, // Example for a checkbox, initialize as needed
    is_talent_request: false,
    is_davis_bacon: false,
    is_union: false,
    // Add other form fields here and initialize them as needed
  };
  const [state, setState] = useState(
    JSON.parse(localStorage.getItem("formData")) || initialState
  );

  const { loading: companyUpdateLoader } = useSelector(
    (state) => state.companyUpdate
  );

  let params = useParams();
  let { company_slug } = params;
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setError(null);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchInput = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
  };
  const handleSelectRegion = (value) => {
    setSelectedRegions([...value]);
    setState((prevState) => ({
      ...prevState,
      working_region: value,
    }));
    // localStorage.setItem('formData', JSON.stringify({...{"working_region":[...value]}}));
  };
  const priceId = process.env.REACT_APP_ALL_APPS;

  const handleBlur = (e) => {
    const { name, value } = e.target;

    const urlFields = ["pre_qualification_url", "plan_room_url", "website"];

    if (urlFields.includes(name)) {
      try {
        new URL(value);
      } catch (error) {
        setError(`Invalid URL entered for ${name.replace("_", " ")}`);
        return;
      }
    }

    // Validate email field
    if (name === "email" && !validateEmail(value)) {
      setError("Invalid email address");
      return;
    }

    setError(null); // Clear any previous error messages when a valid input is received
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else {
      if (user?.subscriptions.length <= 0) {
        window.location = "https://billing.ganarpro.com/b/cN2cQ8aTabX5h1e28t";
        return;
      }
      if (error) {
        setSubmissionError("Please fix the errors before submitting.");
        return;
      }
      if (state) {
        // Clear any previous submission errors when there are no validation errors
        setSubmissionError(null);
        dispatch(updateCompanyDetail(state));
      }
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(getCompanyDetail());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (companyDetail?.data) {
      // Merge backend data and localStorage data (if any), with localStorage data taking precedence
      const mergedData = {
        ...JSON.parse(localStorage.getItem("formData")),
        ...companyDetail?.data,
      };
      setState(mergedData);
    }
  }, [companyDetail?.data]);
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(state));
  }, [state]);

  return (
    <Container className="my-3">
      <Row className="justify-content-between">
        <Col md={6} className="mb-5">
          <h1 className="text-start my-5 display-5 fw-semibold text-capitalize">
            {user?.company_name}
          </h1>
        </Col>
        <Col md={4} className="text-end">
          <Row>
            <Col className="col-6">
              <h1 className="text-center mb-5 display-3 fw-semibold">
                GC Planify
              </h1>
            </Col>
            <Col className="col-1">
              <Image src={diversityLogo} width="50" height="50" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col className="" md={6}>
          <h3 className="fw-bold mb-4 text-center">Working Region</h3>
          <MultiSelectDropdown
            selectedRegions={state?.working_region}
            customHandler={handleSelectRegion}
          />
        </Col>
      </Row>
      {error && (
        <Col md={6}>
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        </Col>
      )}
      <Row>
        <Col className="col-md-4 col-10  ms-5 mb-2 m-sm-">
          <h3 className="fw-bold my-3 text-start"> Diversity and Inclusion</h3>
          <Form.Group
            as={Col}
            md={12}
            className="mb-3 checkbox m-0 toggle-switch"
            controlId="diveristyInclusion"
          >
            <Form.Check
              type="switch"
              name="is_diveristy_inclusion"
              label="Do you believe your company actively promotes
               and supports workplace
              diversity and inclusion"
              className="mb-4"
              checked={state.is_diveristy_inclusion}
              onChange={handleSwitchInput || false}
            />
          </Form.Group>
          {state.is_diveristy_inclusion && (
            <>
              <Form.Group
                as={Col}
                md={12}
                className="mb-3 checkbox m-0 toggle-switch"
                controlId="diversityInitiative"
              >
                <Form.Check
                  type="switch"
                  name="is_diversity_initiative"
                  label="Highlight our diversity initiative
              contributions to construction sector media."
                  className="mb-1"
                  checked={state.is_diversity_initiative}
                  onChange={handleSwitchInput || false}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md={12}
                className="mb-3 checkbox m-0 toggle-switch"
                controlId="workforceInclusion"
              >
                <Form.Check
                  type="switch"
                  name="is_workforce_inclusion"
                  label="Interested in Ganarpro's
                 Workforce inclusion Survey"
                  className="mb-1"
                  checked={state.is_workforce_inclusion}
                  onChange={handleSwitchInput || false}
                />
              </Form.Group>
              {state.is_workforce_inclusion && (
                <Row className="my-4">
                  <Col className="col-md-2 text-start col-2">
                    <FormControl
                      type="text"
                      name="num_of_employees"
                      value={state.num_of_employees || ""}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col className="col-md-8 text-start col-5">
                    <label>Number of employees </label>
                  </Col>
                </Row>
              )}

              <Form.Group
                as={Col}
                md={12}
                className="mb-3 checkbox m-0 toggle-switch"
                controlId="useLivingUnitPricing"
              >
                <Form.Check
                  type="switch"
                  name="is_diversity_champion"
                  label="Does your organization wish to designate a Diversity
               Champion (external and internal communication)"
                  className="mb-1"
                  checked={state.is_diversity_champion}
                  onChange={handleSwitchInput || false}
                />
              </Form.Group>

              {state.is_diversity_champion && (
                <>
                  <Row className="my-3">
                    <Col className="text-start" sm={2}>
                      Contact Name
                    </Col>
                    <Col sm={5} className="text-start">
                      <FormControl
                        type="text"
                        name="contact_name"
                        value={state.contact_name}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col className="text-start " sm={2}>
                      Phone
                    </Col>
                    <Col sm={5} className="text-start">
                      <FormControl
                        type="text"
                        name="contact_phone"
                        onChange={handleChange}
                        value={state.contact_phone}
                      />
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col className="text-start" sm={2}>
                      Email
                    </Col>
                    <Col sm={5} className="text-start">
                      <FormControl
                        type="email"
                        name="contact_email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={state.contact_email}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Form.Group
                as={Col}
                md={12}
                className="mb-3 checkbox m-0 toggle-switch"
                controlId="isAnnualEvent"
              >
                <Form.Check
                  type="switch"
                  name="is_annual_event"
                  label="Interested in participating in an annual event alongside
               other construction-related organizations
                to promote diversity and inclusion"
                  className="mb-1"
                  checked={state.is_annual_event}
                  onChange={handleSwitchInput || false}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md={12}
                className="mb-3 checkbox m-0 toggle-switch"
                controlId="diversityCertification"
              >
                <Form.Check
                  type="switch"
                  name="is_diversity_certification"
                  label="Considering taking the exam to obtain the
              Ganarpro Construction Diversity Certification."
                  className="mb-1"
                  checked={state.is_diversity_certification}
                  onChange={handleSwitchInput || false}
                />
              </Form.Group>
            </>
          )}
        </Col>
        <Col className="col-md-3 col-12  ms-5 mb-2 m-sm-">
          <h3 className="fw-bold my-3 text-start"> Service request</h3>
          <Form.Group
            as={Col}
            md={12}
            className="mb-3 checkbox m-0 toggle-switch my-4"
            controlId="AddITBS"
          >
            <Form.Check
              type="switch"
              name="is_add_itbs"
              label="I want to Add ITBS, post bid opportunities"
              className="mb-4"
              checked={state.is_add_itbs}
              onChange={handleSwitchInput || false}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md={12}
            className="mb-3 checkbox m-0 toggle-switch my-4"
            controlId="headhunter"
          >
            <Form.Check
              type="switch"
              name="is_headhunter"
              label="Talent recruitment headhunter"
              className="mb-1"
              checked={state.is_headhunter}
              onChange={handleSwitchInput || false}
            />
          </Form.Group>
          {state.is_headhunter && (
            <Row>
              <Col>
                {" "}
                <FormControl
                  type="text"
                  name="job_title"
                  value={state.job_title}
                  onChange={handleChange}
                  placeholder="Enter Job title"
                />{" "}
              </Col>
            </Row>
          )}

          <Form.Group
            as={Col}
            md={12}
            className="mb-3 checkbox m-0 toggle-switch my-4"
            controlId="IncreaseSupplierNetwork"
          >
            <Form.Check
              type="switch"
              name="is_increase_supplier_network"
              label="Increase my supplier network"
              className="mb-1"
              checked={state.is_increase_supplier_network}
              onChange={handleSwitchInput || false}
            />
          </Form.Group>
          {state.is_increase_supplier_network && (
            <>
              <Form.Group
                as={Col}
                md={12}
                className="mb-3 checkbox m-0 toggle-switch my-4"
                controlId="shareMore"
              >
                <Form.Check
                  type="switch"
                  name="is_share_more"
                  label="share more"
                  className="mb-1"
                  checked={state.is_share_more}
                  onChange={handleSwitchInput || false}
                />
              </Form.Group>
              {state.is_share_more && (
                <>
                  <Row>
                    <Col md={12} className="col-12">
                      <h3 className="fw-bold">Prequalification application</h3>
                    </Col>
                    <Col md={12} className="col-8 text-center">
                      <input
                        type="text"
                        value={state.pre_qualification_url || ""}
                        name="pre_qualification_url"
                        className="form-control"
                        placeholder="Prequalification application"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="col-12">
                      <h3 className="fw-bold">Plan room</h3>
                    </Col>
                    <Col md={12} className="col-7 text-center">
                      <input
                        type="text"
                        name="plan_room_url"
                        value={state.plan_room_url || ""}
                        className="form-control"
                        placeholder="Plan room"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Col>
        <Col className="col-md-4 col-12  ms-5 mb-2 m-sm-0">
          <h3 className="text-start my-3 fw-bold">Public work involvement</h3>
          <Form.Group
            as={Col}
            md={12}
            className="mb-3 checkbox m-0 toggle-switch"
            controlId="davisBaconPrevailingWage"
          >
            <Form.Check
              type="switch"
              name="is_davis_bacon"
              label="Davis Bacon Prevailing wage"
              className="mb-4"
              checked={state.is_davis_bacon}
              onChange={handleSwitchInput || false}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            md={12}
            className="mb-3 checkbox m-0 toggle-switch"
            controlId="unionAffiliated"
          >
            <Form.Check
              type="switch"
              name="is_union"
              label="Union affiliated"
              className="mb-4"
              checked={state.is_union || false}
              onChange={handleSwitchInput}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-5 justify-content-md-end">
        <Col className="text-start col-1">
          <button
            disabled={companyUpdateLoader}
            className="btn btn-primary p-3 rounded-4"
            onClick={handleSubmit}
          >
            <span className="fw-bold heading-4">
              Activate Enrollment
              {companyUpdateLoader && (
                <span className="spinner-grow spinner-grow-sm"> </span>
              )}
            </span>
          </button>
        </Col>
      </Row>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />
      <PlanifySubscriptionModal
        show={paymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
    </Container>
  );
};

export default GCPlanify;
