import { combineReducers } from "redux";
import auth from "./authentication/authentication.reducer";
import projects from "./projects/projects.reducer";
import comments from "./projects/comments.reducer";
import {
  projectTypeDetailReducer,
  projectTypeReducer,
  proposalCreateReducer,
  proposalDetailReducer,
  proposalDownloadReducer,
  proposalListReducer,
  proposalSubscriptionReducer,
  proposalUpdateReducer,
  proposalValuesReducer,
} from "./proposal/proposalReducer";
import {
  getPlanifyCompanyDetailsReducer,
  manageUserBillingReducer,
  passwordResetConfirmReducer,
  passwordResetReducer,
  sendDomainVerification, UpdatePlanifyCompanyDetailsReducer,
  userDetailsReducer,
  userSetFreeModeCount,
  userUpdateProfileReducer
} from "./users/userReducer";
import {
  bidAmountPricingReducer,
  bidAmountValuesReducer,
  cleanupEstimatesCreateReducer,
  cleanupEstimatesDetailReducer,
  cleanupEstimatesListReducer,
  cleanupEstimatesUpdateReducer,
  costProfitValuesReducer,
  projectTypePricingListReducer,
  stateLaborPricingListReducer,
  stateLaborPricingReducer,
  calculationInfoReducer,
  calculationInfoSavedReducer,
} from "./mortgageCalculator/mortgageCalculatorReducer";
import {
  gcQualifyCompanyListReducer,
  planRoomUpdateReducer,
  regionListReducer,
} from "./gcQualify/gcQualifyReducer";
import {
  companyBasicInfoUpdateReducer,
  companyFormsProgressReducer,
  companyBasicInfoReducer,
  companyContinueInfoReducer,
  companyContinueInfoUpdateReducer,
  companySocialInfoReducer,
  companySocialInfoUpdateReducer,
  companyOrgDetailsReducer,
  companyOrgDetailsUpdateReducer,
  companyHistoryReducer,
  companyHistoryUpdateReducer,
  companyCurrentWorkReducer,
  companyCurrentWorkUpdateReducer,
  companyWorkReducer,
  companyWorkUpdateReducer,
  companyInsuranceReducer,
  companyInsuranceUpdateReducer,
  companySafetyReducer,
  companySafetyUpdateReducer,
  companyFinanceReducer,
  companyFinanceUpdateReducer,
  companyLegalReducer,
  companyLegalUpdateReducer,
  companyShippingReducer,
  companyShippingUpdateReducer,
  companySupplierReducer,
  companySupplierUpdateReducer,
  projectTypesReducer,
} from "./companyDetails/companyDetailsReducer";

export default combineReducers({
  auth,
  projects,
  comments,
  proposalCreate: proposalCreateReducer,
  proposalDownload: proposalDownloadReducer,
  proposalList: proposalListReducer,
  proposalDetail: proposalDetailReducer,
  proposalValues: proposalValuesReducer,
  progressInfo: companyFormsProgressReducer,
  projectTypes: projectTypesReducer,
  basicInfoUpdate: companyBasicInfoUpdateReducer,
  basicInfo: companyBasicInfoReducer,
  continueInfoUpdate: companyContinueInfoUpdateReducer,
  continueInfo: companyContinueInfoReducer,
  socialInfoUpdate: companySocialInfoUpdateReducer,
  socialInfo: companySocialInfoReducer,
  orgDetailsInfo: companyOrgDetailsReducer,
  orgDetailsUpdate: companyOrgDetailsUpdateReducer,
  historyInfo: companyHistoryReducer,
  historyUpdate: companyHistoryUpdateReducer,
  currentWorkInfo: companyCurrentWorkReducer,
  currentWorkUpdate: companyCurrentWorkUpdateReducer,
  workInfo: companyWorkReducer,
  workUpdate: companyWorkUpdateReducer,
  insuranceInfo: companyInsuranceReducer,
  insuranceUpdate: companyInsuranceUpdateReducer,
  safetyInfo: companySafetyReducer,
  safetyUpdate: companySafetyUpdateReducer,
  financeInfo: companyFinanceReducer,
  financeUpdate: companyFinanceUpdateReducer,
  supplierInfo: companySupplierReducer,
  supplierUpdate: companySupplierUpdateReducer,
  legalInfo: companyLegalReducer,
  legalUpdate: companyLegalUpdateReducer,
  shippingInfo: companyShippingReducer,
  shippingUpdate: companyShippingUpdateReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  proposalSubscription: proposalSubscriptionReducer,
  projectTypeList: projectTypeReducer,
  projectTypeDetail: projectTypeDetailReducer,
  projectUpdate: proposalUpdateReducer,
  passwordReset: passwordResetReducer,
  passwordResetConfirm: passwordResetConfirmReducer,
  bidAmountValues: bidAmountValuesReducer,
  bidAmountPricing: bidAmountPricingReducer,
  stateLaborPricing: stateLaborPricingReducer,
  stateLaborPricingList: stateLaborPricingListReducer,
  projectTypePricingList: projectTypePricingListReducer,
  costProfitValues: costProfitValuesReducer,
  cleanUpEstimateCreate: cleanupEstimatesCreateReducer,
  cleanUpEstimateUpdate: cleanupEstimatesUpdateReducer,
  cleanUpEstimateList: cleanupEstimatesListReducer,
  cleanUpEstimateDetail: cleanupEstimatesDetailReducer,
  regionList: regionListReducer,
  gcQualifyCompanyList: gcQualifyCompanyListReducer,
  userFreeModeCount: userSetFreeModeCount,
  manageUserBilling: manageUserBillingReducer,
  planRoomUpdate: planRoomUpdateReducer,
  calculationInfo: calculationInfoReducer,
  calculationSaved: calculationInfoSavedReducer,
  domainVerification: sendDomainVerification,
  companyUpdate: UpdatePlanifyCompanyDetailsReducer,
  companyDetail: getPlanifyCompanyDetailsReducer,
});
