import React, { useState } from "react";
import { Alert, Button, Col } from "react-bootstrap";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { createSubscriptionAPI } from "../../utils/requests/proposals";
import { toastError, toastSuccess } from "../../utils/helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const useOptions = () => {
  return {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#87BBFD",
        },
      },
      invalid: {
        iconColor: "#eb1c26",
        color: "#eb1c26",
      },
    },
  };
};
const StripePaymentContainer = ({ product }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const options = useOptions();
  const { user } = useSelector((state) => state.auth);
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setError(null);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (payload.error) {
      setError(payload.error);
    } else {
      setLoading(true);
      const data = {
        payment_method: payload.paymentMethod.id,
        price_id: product.price_id,
        ...user,
      };

      createSubscriptionAPI(data)
        .then(async (response) => {
          toastSuccess("Payment is Successful");

          localStorage.setItem("user", JSON.stringify(response));
          // dispatch({
          //   type: SUBSCRIPTION_CREATE_SUCCESS,
          //   payload: response,
          // });
          setTimeout(() => {
            navigate("/");
          }, 3);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toastError(`Payment Failed`);
        });
      // dispatch(
      //   createSubscription({
      //     payment_method: payload.paymentMethod.id,
      //     price_id: process.env.REACT_APP_STRIPE_PRICE_ID,
      //     ...user,
      //   })
      // );
      setLoading(true);
    }
  };
  return (
    <form
      className="justify-content-center text-center my-5 col-md-12"
      onSubmit={handleSubmit}
    >
      {error && <Alert variant="danger">{error.message}</Alert>}
      <CardElement
        options={options}
        onChange={(e) => {
          setError(e.error);
          // setCardComplete(e.complete);
        }}
        className="my-5"
      />
      <Button
        type="submit"
        variant="primary"
        className="p-3 text-center mt-3"
        disabled={!stripe || loading}
      >
        {loading ? (
          "processing..."
        ) : (
          <h3 className="fw-bold heading">Pay $499</h3>
        )}
      </Button>
    </form>
  );
};

export default StripePaymentContainer;
