export const USER_SIGNUP_START = "USER_SIGNUP_START";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";

export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_RELOAD_SUCCESS = "USER_RELOAD_SUCCESS";

export const FETCH_PROJECTS_START = "FETCH_PROJECTS_START";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAIL = "FETCH_PROJECTS_FAIL";
export const FETCH_SINGLE_PROJECT_SUCCESS = "FETCH_SINGLE_PROJECT_SUCCESS";
export const ARCHIVE_PROJECT_SUCCESS = "ARCHIVE_PROJECT_SUCCESS";
export const UNARCHIVE_PROJECT_SUCCESS = "UNARCHIVE_PROJECT_SUCCESS";
export const FAVOURITE_PROJECT_SUCCESS = "FAVOURITE_PROJECT_SUCCESS";
export const UNFAVOURITE_PROJECT_SUCCESS = "UNFAVOURITE_PROJECT_SUCCESS";
export const PROJECT_ACTION_FAILED = "PROJECT_ACTION_FAILED";

export const PROJECT_SAVE_SEARCH_START = "PROJECT_SAVE_SEARCH_START";
export const PROJECT_SAVE_SEARCH_SUCCESS = "PROJECT_SAVE_SEARCH_SUCCESS";
export const PROJECT_SAVE_SEARCH_FAILED = "PROJECT_SAVE_SEARCH_FAILED";

export const UPDATE_FAVOURITE_PROJECT_SUCCESS =
  "UPDATE_FAVOURITE_PROJECT_SUCCESS";

export const COMMENT_START = "SAVE_COMMENT_START";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const COMMENT_FAILED = "SAVE_COMMENT_FAILED";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";

export const REPLY_START = "REPLY_START";
export const SAVE_REPLY_SUCCESS = "SAVE_REPLY_SUCCESS";
export const GET_REPLY_SUCCESS = "GET_REPLY_SUCCESS";
export const REPLY_FAILED = "SAVE_REPLY_FAILED";
export const DELETE_REPLY_SUCCESS = "DELETE_REPLY_SUCCESS";

export const GET_EMAIL_TEMPLATE_START = "GET_EMAIL_TEMPLATE_START";
export const GET_EMAIL_TEMPLATE_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS";
export const GET_EMAIL_TEMPLATE_FAILED = "GET_EMAIL_TEMPLATE_FAILED";

export const GET_USER_NOTIFICATIONS_SUCCESS = "GET_USER_NOTIFICATIONS_SUCCESS";

export const PASSWORD_RESET_START = "PASSWORD_RESET_START";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";

export const PASSWORD_RESET_CONFIRM_START = "PASSWORD_RESET_START";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_FAIL";

export const DOMAIN_VERIFICATION_REQUEST = "DOMAIN_VERIFICATION_REQUEST";
export const DOMAIN_VERIFICATION_SUCCESS = "DOMAIN_VERIFICATION_SUCCESS";
export const DOMAIN_VERIFICATION_FAIL = "DOMAIN_VERIFICATION_FAIL";
