import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Image } from "react-bootstrap";
import {
  StyledAccordionHeader,
  StyledForm,
} from "../../components/ProposalForm/proposalForm.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetail,
  updateUserProfile,
} from "../../store/actions/users/users.actions";
import Spinner from "../../components/spinner/Spinner";
import Message from "../../components/Message";
import styled from "styled-components";
import { USER_UPDATE_RESET } from "../../store/constants/userConstants";
import { useNavigate } from "react-router";
import { State } from "country-state-city";
import ProfileFileUploader from "../../components/forms/ProfileFileUploader";
import { formatPhoneNumber } from "../../utils/helpers/helper";
import IntlTelInput from "react-intl-tel-input";
import { Link } from "react-router-dom";

const StyledSpinner = styled(Spinner)`
  color: red;
  border: 1px red;
  .lds-dual-ring {
    text-align: center;
    color: red;
  }
`;
const Profile = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { id } = user;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user: profileUser } = userDetails;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success, error: errorUpdate } = userUpdateProfile;

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [company_state, setCompanyState] = useState("");
  const [company_street, setCompanyStreet] = useState("");
  const [company_city, setCompanyCity] = useState("");
  const [company_zip, setCompanyZip] = useState("");
  const [proposal_point_contact_name, setProposalContactName] = useState("");
  const [proposal_point_contact_phone, setProposalPhone] = useState("");
  const [proposal_point_contact_email, setProposalContactEmail] = useState("");
  const [job_site_contact_name, setJobSiteContactName] = useState("");
  const [job_site_contact_phone, setJobSiteContactPhone] = useState("");
  const [job_site_contact_email, setJobSiteContactEmail] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [file_url, setSelectedFileURL] = useState("");
  const [outbound_email, setOutBoundEmail] = useState("");
  const dispatch = useDispatch();

  // const [percentage, setPercentage] = useState(0);

  let state = State.getStatesOfCountry("US");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      if (!profileUser?.email || success) {
        dispatch({ type: USER_UPDATE_RESET });
        dispatch(getUserDetail());
      } else {
        setFirstName(profileUser?.first_name);
        setLastName(profileUser?.last_name);
        setEmail(profileUser?.email);
        setOutBoundEmail(profileUser?.outbound_email);
        setPhone(profileUser?.phone);
        setCompanyName(profileUser?.company_name);
        setCompanyStreet(profileUser?.company_street);
        setCompanyCity(profileUser?.company_city);
        setCompanyState(profileUser?.company_state);
        setCompanyZip(profileUser?.company_zip);
        setProposalContactName(profileUser?.proposal_point_contact_name);
        setProposalPhone(profileUser?.proposal_point_contact_phone);
        setProposalContactEmail(profileUser?.proposal_point_contact_email);
        setJobSiteContactName(profileUser?.job_site_contact_name);
        setJobSiteContactPhone(profileUser?.job_site_contact_phone);
        setJobSiteContactEmail(profileUser?.job_site_contact_email);
        setSelectedFileURL(profileUser?.file_url);
      }
    }
  }, [
    dispatch,
    id,
    profileUser?.first_name,
    profileUser?.last_name,
    profileUser?.email,
    profileUser?.outbound_email,
    profileUser?.phone,
    profileUser?.company_name,
    profileUser?.company_street,
    profileUser?.company_city,
    profileUser?.company_zip,
    profileUser?.file_url,
    profileUser?.proposal_point_contact_name,
    profileUser?.proposal_point_contact_phone,
    profileUser?.proposal_point_contact_email,
    profileUser?.job_site_contact_name,
    profileUser?.job_site_contact_phone,
    profileUser?.job_site_contact_email,
    profileUser,
    success,
    user,
    navigate,
  ]);
  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("outbound_email", outbound_email);
    formData.append("phone", phone);
    formData.append("company_name", company_name || "");
    formData.append("company_state", company_state || "");
    formData.append("company_street", company_street || "");
    formData.append("company_zip", company_zip || "");
    formData.append(
      "proposal_point_contact_name",
      proposal_point_contact_name || ""
    );
    formData.append(
      "proposal_point_contact_phone",
      proposal_point_contact_phone || ""
    );
    formData.append(
      "proposal_point_contact_email",
      proposal_point_contact_email || ""
    );
    formData.append("job_site_contact_name", job_site_contact_name || "");
    formData.append("job_site_contact_phone", job_site_contact_phone || "");
    formData.append("job_site_contact_email", job_site_contact_email || "");
    formData.append("company_city", company_city || "");
    formData.append("image", selectedFile);
    dispatch(updateUserProfile(formData));
  };

  return (
    <StyledForm
      className="my-5 justify-content-center col-md-7 mx-auto"
      encType="multipart/form-data"
    >
      {loading ? (
        <div className="text-center">
          <StyledSpinner />
        </div>
      ) : error ? (
        <Message variant="danger text-center">{error}</Message>
      ) : (
        <>
          {errorUpdate && (
            <Message variant="danger">
              {Object.keys(errorUpdate).map((error) => {
                return (
                  <p>
                    {" "}
                    {error}: {errorUpdate[error].toString()}
                  </p>
                );
              })}
            </Message>
          )}
          <Row className="mb-4">
            <Col>
              <Link
                to="/"
                variant="primary"
                className="btn btn-primary text-decoration-none"
                type="submit"
              >
                <h4 className="p-2">Back</h4>
              </Link>
            </Col>
          </Row>
          <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
            <Accordion.Item className="bg-white" eventKey="0">
              <StyledAccordionHeader>
                Personal Information
              </StyledAccordionHeader>
              <Accordion.Body className="mb-5">
                <Row className="my-4">
                  <Form.Group as={Col} controlId="formGridCustomerCompanyName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      name="first_name"
                      value={first_name}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridCustomerCompanyName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      name="last_name"
                      value={last_name}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row className="mt-4">
                  <Form.Group as={Col} controlId="formGridCustomerCompanyName">
                    <Form.Label>Phone</Form.Label>
                    <IntlTelInput
                      containerClassName="intl-tel-input d-block"
                      inputClassName="form-control"
                      allowDropdown={false}
                      countries={["us"]}
                      preferredCountries={["us"]}
                      type="tel"
                      autoComplete="tel"
                      onPhoneNumberChange={(isValid, phone, country) => {
                        setPhone(formatPhoneNumber(phone));
                      }}
                      value={phone || ""}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridCustomerCompanyName">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      disabled
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Row>
              <Col className="text-center mt-4 p-4">
                <Button
                  variant="primary"
                  className=""
                  type="submit"
                  onClick={submitHandler}
                >
                  <h4 className="p-2">Update</h4>
                </Button>
              </Col>
            </Row>
          </Accordion>
        </>
      )}
    </StyledForm>
  );
};
export default Profile;
