import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import {
  getCompanyDetail,
  getUserDetail,
  updateCompanyDetail,
} from "../../store/actions/users/users.actions";
import LoginModal from "../../components/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const GCPlanify = () => {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const { loading, companyDetail } = useSelector(
    (state) => state.companyDetail
  );
  const initialState = {
    pre_qualification_url: "",
    plan_room_url: "",
    working_region: [], // Example for a multi-select field, initialize as an empty array
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    website: "",
    is_add_itbs: false, // Example for a checkbox, initialize as needed
    is_talent_request: false,
    is_davis_bacon: false,
    is_union: false,
    // Add other form fields here and initialize them as needed
  };
  const [state, setState] = useState(initialState);

  const { loading: companyUpdateLoader } = useSelector(
    (state) => state.companyUpdate
  );
  let params = useParams();
  let { company_slug } = params;
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSwitchInput = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
  };
  const handleSelectRegion = (value) => {
    setSelectedRegions([...value]);
    setState((prevState) => ({
      ...prevState,
      working_region: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else {
      if (state) {
        dispatch(updateCompanyDetail(state));
      }
    }
  };
  useEffect(() => {
    if (user) {
      dispatch(getCompanyDetail(company_slug ? company_slug : ""));
    }
  }, [company_slug, dispatch, user]);

  // Update the component state when companyDetail changes.
  useEffect(() => {
    if (companyDetail?.data) {
      setState(companyDetail?.data);
    }
  }, [companyDetail?.data]);

  return (
    <Container className="my-3">
      <Row className="justify-content-between">
        <Col md={6} className="mb-5">
          <h1 className="text-start my-5 display-3 fw-semibold text-capitalize">
            {state.company_name}
          </h1>
          <Row className="">
            <Col className="" md={12}>
              <h3 className="fw-bold mb-4 text-center">Working Region</h3>
              <MultiSelectDropdown
                selectedRegions={state?.working_region}
                customHandler={handleSelectRegion}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4} className="my-5">
          <h1 className="text-center mb-5 display-3 fw-semibold">GC Planify</h1>
          <button className="rounded-4 px-5 py-2">View</button>
        </Col>
      </Row>
      <Row>
        <Col md={5} lg={3} className="mx-auto text-center">
          <Row>
            <Col md={12} className="col-4">
              <h3 className="fw-bold">Prequalification application</h3>
            </Col>
            <Col md={12} className="col-7 text-center">
              <input
                type="text"
                value={state.pre_qualification_url || ""}
                name="pre_qualification_url"
                className="form-control"
                placeholder="Prequalification application"
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>

        <Col md={2} lg={3}>
          <Row>
            <Col md={12} className="col-4">
              <h3 className="fw-bold">Plan room</h3>
            </Col>
            <Col md={12} className="col-7 text-center">
              <input
                type="text"
                name="plan_room_url"
                value={state.plan_room_url || ""}
                className="form-control"
                placeholder="Plan room"
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col md={3} lg={3}>
          <h3 className="fw-bold mt-xs-4">Socials</h3>
          <div className="form-group row my-3">
            <label className="col-sm-4 col-form-label  fw-bold col-4">
              LinkedIn
            </label>
            <div className="col-sm-6 col-6">
              <input
                type="text"
                name="linkedin"
                value={state.linkedin || ""}
                className="form-control"
                placeholder="LinkedIn"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row my-4">
            <label className="col-sm-4 col-form-label  fw-bold col-4">
              Facebook
            </label>
            <div className="col-sm-6 col-6">
              <input
                name="facebook"
                type="text"
                value={state.facebook || ""}
                className="form-control"
                placeholder="Facebook"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row my-4">
            <label className="col-sm-4 col-form-label  fw-bold col-4">
              Twitter
            </label>
            <div className="col-sm-6 col-6">
              <input
                type="text"
                name="twitter"
                value={state.twitter || ""}
                className="form-control"
                placeholder="Twitter"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row my-4">
            <label className="col-sm-4 col-form-label  fw-bold col-4">
              Instagram
            </label>
            <div className="col-sm-6 col-6">
              <input
                type="text"
                name="instagram"
                value={state.instagram || ""}
                className="form-control"
                placeholder="Instagram"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row my-4">
            <label className="col-sm-4 col-form-label fw-bold col-4">
              Youtube
            </label>
            <div className="col-sm-6 col-6">
              <input
                type="text"
                name="youtube"
                value={state.youtube || ""}
                className="form-control"
                placeholder="Youtube"
                onChange={handleChange}
              />
            </div>
          </div>
        </Col>
        <Col md={2} lg={3}>
          <Row>
            <Col md={12} className="col-4">
              <h3 className="fw-bold">Website</h3>
            </Col>
            <Col md={12} className="col-7 text-center">
              <input
                type="text"
                name="website"
                value={state.website || ""}
                className="form-control"
                placeholder="Website"
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={8}>
          <Row className="my-3 mx-3">
            <Form.Group
              as={Col}
              md={5}
              lg={5}
              className="mb-3 checkbox m-0 toggle-switch"
              controlId="price_per_sqft"
            >
              <Form.Check
                type="switch"
                name="is_add_itbs"
                label="I want to Add ITBS, post bid opportunities"
                className="mb-4"
                checked={state.is_add_itbs}
                onChange={handleSwitchInput || false}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={5}
              lg={5}
              className="mb-3 checkbox m-0 toggle-switch"
              controlId="useLivingUnitPricing"
            >
              <Form.Check
                type="switch"
                name="is_talent_request"
                label="Talent recruitment request"
                className="mb-1"
                checked={state.is_talent_request}
                onChange={handleSwitchInput || false}
              />
            </Form.Group>

            <Form.Group
              as={Col}
              md={5}
              lg={5}
              className="mb-3 checkbox m-0 toggle-switch"
              controlId="useNumberoFDays"
            >
              <Form.Check
                type="switch"
                name="is_davis_bacon"
                label="Davis Bacon"
                className="mb-4"
                checked={state.is_davis_bacon}
                onChange={handleSwitchInput || false}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={5}
              lg={5}
              className="mb-3 checkbox m-0 toggle-switch"
              controlId="useNumberoFDays"
            >
              <Form.Check
                type="switch"
                name="is_union"
                label="Union"
                className="mb-4"
                checked={state.is_union || false}
                onChange={handleSwitchInput}
              />
            </Form.Group>
          </Row>
        </Col>
        <Row className="mb-5 justify-content-md-end">
          <Col className="text-start col-1">
            <button
              disabled={companyUpdateLoader}
              className="btn btn-primary p-3 rounded-4"
              onClick={handleSubmit}
            >
              <span className="fw-bold heading-4">
                Enroll{" "}
                {companyUpdateLoader && (
                  <span className="spinner-grow spinner-grow-sm"> </span>
                )}
              </span>
            </button>
          </Col>
        </Row>
      </Row>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />
    </Container>
  );
};

export default GCPlanify;
