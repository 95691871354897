import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert, Button, Col, OverlayTrigger, Row } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import allApps from "../../assets/img/All-Projects-150.jpg";
import { useDispatch, useSelector } from "react-redux";
import StripePaymentContainer from "../StripePaymentContainer";
import * as PropTypes from "prop-types";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { createSubscriptionAPI } from "../../utils/requests/proposals";
import { toastError, toastSuccess } from "../../utils/helpers/helper";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const product = {
  id: 1,
  name: "Ganarpro All Apps",
  amount: "$44.99",
  canceled_amount: "$99.99",
  image: allApps,
  price_id: process.env.REACT_APP_ALL_APPS,
};

function StripePymentContainer(props) {
  return null;
}

const useOptions = () => {
  return {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#87BBFD",
        },
      },
      invalid: {
        iconColor: "#eb1c26",
        color: "#eb1c26",
      },
    },
  };
};

const PlanifySubscriptionModal = (props) => {
  const { user: profile } = useSelector((state) => state.userDetails);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="text-center justify-content-center"
    >
      <Modal.Header closeButton={props.closeButton} className="text-end">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center jus"
        >
          CREDIT/DEBIT CARD PAYMENT
          <img
            alt="logo"
            src="https://img.icons8.com/color/36/000000/visa.png"
          />
          <img
            alt="logo"
            src="https://img.icons8.com/color/36/000000/mastercard.png"
          />
          <img
            alt="logo"
            src="https://img.icons8.com/color/36/000000/amex.png"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-md-center">
          <Col md="8">
            <h3 className="text-center">Enter card details</h3>
            <Elements stripe={stripePromise}>
              <StripePaymentContainer product={product} />
            </Elements>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/*<Button onClick={props.onHide}>Close</Button>*/}
      </Modal.Footer>
    </Modal>
  );
};
PlanifySubscriptionModal.defaultProps = {
  closeButton: true,
};
export default PlanifySubscriptionModal;
