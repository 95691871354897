import React from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink, useNavigate } from "react-router-dom";
import homeIcon from "../../../assets/img/sidebar/svg/home.svg";
import projectIcon from "../../../assets/img/sidebar/svg/projects.svg";
import calculatorIcon from "../../../assets/img/sidebar/svg/calculator.svg";
import proposalIcon from "../../../assets/img/sidebar/svg/proposal.svg";
import prequalifyIcon from "../../../assets/img/sidebar/svg/prequalify.svg";
import my_companyIcon from "../../../assets/img/sidebar/svg/my_company.jpg";
import forgot_passwordIcon from "../../../assets/img/sidebar/svg/forgot_password.svg";
import { getManageUserBilling } from "../../../store/actions/users/users.actions";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/actions/authentication.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCreditCard,
  faMoneyBill,
  faSignIn,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import Collapse from "react-bootstrap/Collapse";
import DropDownMenu from "./DropDownMenu";

const sideBarList = [
  {
    name: "Home",
    to: "/",
    icon: homeIcon,
    divider: true,
    hasSubCategory: false,
  },
  // {
  //   name: "Manage Company",
  //   to: "/gcplanify-setup",
  //   icon: my_companyIcon,
  //   divider: true,
  //   hasSubCategory: false,
  // },
];

// function SidebarV3({ name, ...props }) {
function SidebarV3({
  show,
  toggleSideBarHandler,
  closeSidebarHandler,
  ...props
}) {
  //   const [show, setShow] = useState(false);

  //   const handleClose = () => setShow(false);
  //   const toggleShow = () => setShow((s) => !s);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { loading: billingLoader, disabled } = useSelector(
    (state) => state.manageUserBilling
  );
  const navigate = useNavigate();

  const onLogoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
  };
  const handleManageBilling = (e) => {
    e.preventDefault();
    dispatch(getManageUserBilling(user?.customer));
  };
  const closeSidebar = (e, link) => {
    e.preventDefault();
    toggleSideBarHandler(false);
    navigate(link);
  };
  return (
    <>
      <Button
        variant="none"
        onClick={toggleSideBarHandler}
        className="p-0 fs-1"
      >
        <span className="sidebar-open-v2">&#9776;</span>
      </Button>
      <Offcanvas show={show} onHide={closeSidebarHandler} {...props}>
        <Offcanvas.Header>
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          <Offcanvas.Title onClick={toggleSideBarHandler} role="button">
            <span className="sidebar-open-v2">&#9776;</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {sideBarList?.map((list, index) => (
            <DropDownMenu list={list} key={index} closeSidebar={closeSidebar} />
          ))}
          {user && (
            <div>
              <NavLink
                to="/"
                className="sidebar-links my-3"
                onClick={onLogoutHandler}
              >
                <FontAwesomeIcon
                  icon={faSignOut}
                  size="xl"
                  style={{ width: 60 }}
                  className="cursor-pointer ms-lg-4 "
                />
                <span>Logout</span>
              </NavLink>
            </div>
          )}
          {!user && (
            <div>
              <NavLink to="/login" className="sidebar-links my-3 ">
                <FontAwesomeIcon
                  icon={faSignIn}
                  size="xl"
                  style={{ width: 60 }}
                  className="cursor-pointer ms-lg-4 "
                />
                <span className="">Login</span>
              </NavLink>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

// function Example() {
//   return (
//     <>
//   {options.map((props, idx) => (
//     <OffCanvasExample key={idx} {...props} />
//   ))}
//     </>
//   );
// }

// render(<Example />);

export default SidebarV3;
