export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_SET_FREE_MODE_REQUEST = "USER_SET_FREE_MODE_REQUEST";
export const USER_SET_FREE_MODE_SUCCESS = "USER_SET_FREE_MODE_SUCCESS";
export const USER_SET_FREE_MODE_FAIL = "USER_SET_FREE_MODE_FAIL";

export const MANAGE_USER_BILLING_REQUEST = "MANAGE_USER_BILLING_REQUEST";
export const MANAGE_USER_BILLING_SUCCESS = "MANAGE_USER_BILLING_SUCCESS";
export const MANAGE_USER_BILLING_FAIL = "MANAGE_USER_BILLING_FAIL";

export const COMPANY_DETAILS_PROJECT_TYPES_REQUEST = 'COMPANY_DETAILS_PROJECT_TYPES_REQUEST';
export const COMPANY_DETAILS_PROJECT_TYPES_SUCCESS = 'COMPANY_DETAILS_PROJECT_TYPES_SUCCESS';
export const COMPANY_DETAILS_PROJECT_TYPES_FAIL = 'COMPANY_DETAILS_PROJECT_TYPES_FAIL';

export const COMPANY_DETAILS_PROGRESS_REQUEST = 'COMPANY_DETAILS_PROGRESS_REQUEST';
export const COMPANY_DETAILS_PROGRESS_SUCCESS = 'COMPANY_DETAILS_PROGRESS_SUCCESS';
export const COMPANY_DETAILS_PROGRESS_FAIL = 'COMPANY_DETAILS_PROGRESS_FAIL';

export const COMPANY_DETAILS_BASIC_REQUEST = 'COMPANY_DETAILS_BASIC_REQUEST';
export const COMPANY_DETAILS_BASIC_SUCCESS = 'COMPANY_DETAILS_BASIC_SUCCESS';
export const COMPANY_DETAILS_BASIC_FAIL = 'COMPANY_DETAILS_BASIC_FAIL';

export const COMPANY_DETAILS_BASIC_UPDATE_REQUEST = 'COMPANY_DETAILS_BASIC_UPDATE_REQUEST';
export const COMPANY_DETAILS_BASIC_UPDATE_SUCCESS = 'COMPANY_DETAILS_BASIC_UPDATE_SUCCESS';
export const COMPANY_DETAILS_BASIC_UPDATE_FAIL = 'COMPANY_DETAILS_BASIC_UPDATE_FAIL';

export const COMPANY_DETAILS_CONTINUE_REQUEST = 'COMPANY_DETAILS_CONTINUE_REQUEST';
export const COMPANY_DETAILS_CONTINUE_SUCCESS = 'COMPANY_DETAILS_CONTINUE_SUCCESS';
export const COMPANY_DETAILS_CONTINUE_FAIL = 'COMPANY_DETAILS_CONTINUE_FAIL';

export const COMPANY_DETAILS_CONTINUE_UPDATE_REQUEST = 'COMPANY_DETAILS_CONTINUE_UPDATE_REQUEST';
export const COMPANY_DETAILS_CONTINUE_UPDATE_SUCCESS = 'COMPANY_DETAILS_CONTINUE_UPDATE_SUCCESS';
export const COMPANY_DETAILS_CONTINUE_UPDATE_FAIL = 'COMPANY_DETAILS_CONTINUE_UPDATE_FAIL';

export const COMPANY_DETAILS_SOCIAL_REQUEST = 'COMPANY_DETAILS_SOCIAL_REQUEST';
export const COMPANY_DETAILS_SOCIAL_SUCCESS = 'COMPANY_DETAILS_SOCIAL_SUCCESS';
export const COMPANY_DETAILS_SOCIAL_FAIL = 'COMPANY_DETAILS_SOCIAL_FAIL';

export const COMPANY_DETAILS_SOCIAL_UPDATE_REQUEST = 'COMPANY_DETAILS_SOCIAL_UPDATE_REQUEST';
export const COMPANY_DETAILS_SOCIAL_UPDATE_SUCCESS = 'COMPANY_DETAILS_SOCIAL_UPDATE_SUCCESS';
export const COMPANY_DETAILS_SOCIAL_UPDATE_FAIL = 'COMPANY_DETAILS_SOCIAL_UPDATE_FAIL';

export const COMPANY_ORG_DETAILS_REQUEST = 'COMPANY_ORG_DETAILS_REQUEST';
export const COMPANY_ORG_DETAILS_SUCCESS = 'COMPANY_ORG_DETAILS_SUCCESS';
export const COMPANY_ORG_DETAILS_FAIL = 'COMPANY_ORG_DETAILS_FAIL';

export const COMPANY_ORG_DETAILS_UPDATE_REQUEST = 'COMPANY_ORG_DETAILS_UPDATE_REQUEST';
export const COMPANY_ORG_DETAILS_UPDATE_SUCCESS = 'COMPANY_ORG_DETAILS_UPDATE_SUCCESS';
export const COMPANY_ORG_DETAILS_UPDATE_FAIL = 'COMPANY_ORG_DETAILS_UPDATE_FAIL';

export const COMPANY_HISTORY_REQUEST = 'COMPANY_HISTORY_REQUEST';
export const COMPANY_HISTORY_SUCCESS = 'COMPANY_HISTORY_SUCCESS';
export const COMPANY_HISTORY_FAIL = 'COMPANY_HISTORY_FAIL';

export const COMPANY_HISTORY_UPDATE_REQUEST = 'COMPANY_HISTORY_UPDATE_REQUEST';
export const COMPANY_HISTORY_UPDATE_SUCCESS = 'COMPANY_HISTORY_UPDATE_SUCCESS';
export const COMPANY_HISTORY_UPDATE_FAIL = 'COMPANY_HISTORY_UPDATE_FAIL';

export const COMPANY_CURRENT_WORK_REQUEST = 'COMPANY_CURRENT_WORK_REQUEST';
export const COMPANY_CURRENT_WORK_SUCCESS = 'COMPANY_CURRENT_WORK_SUCCESS';
export const COMPANY_CURRENT_WORK_FAIL = 'COMPANY_CURRENT_WORK_FAIL';

export const COMPANY_CURRENT_WORK_UPDATE_REQUEST = 'COMPANY_CURRENT_WORK_UPDATE_REQUEST';
export const COMPANY_CURRENT_WORK_UPDATE_SUCCESS = 'COMPANY_CURRENT_WORK_UPDATE_SUCCESS';
export const COMPANY_CURRENT_WORK_UPDATE_FAIL = 'COMPANY_CURRENT_WORK_UPDATE_FAIL';

export const COMPANY_WORK_REQUEST = 'COMPANY_WORK_REQUEST';
export const COMPANY_WORK_SUCCESS = 'COMPANY_WORK_SUCCESS';
export const COMPANY_WORK_FAIL = 'COMPANY_WORK_FAIL';

export const COMPANY_WORK_UPDATE_REQUEST = 'COMPANY_WORK_UPDATE_REQUEST';
export const COMPANY_WORK_UPDATE_SUCCESS = 'COMPANY_WORK_UPDATE_SUCCESS';
export const COMPANY_WORK_UPDATE_FAIL = 'COMPANY_WORK_UPDATE_FAIL';

export const COMPANY_INSURANCE_REQUEST = 'COMPANY_INSURANCE_REQUEST';
export const COMPANY_INSURANCE_SUCCESS = 'COMPANY_INSURANCE_SUCCESS';
export const COMPANY_INSURANCE_FAIL = 'COMPANY_INSURANCE_FAIL';

export const COMPANY_INSURANCE_UPDATE_REQUEST = 'COMPANY_INSURANCE_UPDATE_REQUEST';
export const COMPANY_INSURANCE_UPDATE_SUCCESS = 'COMPANY_INSURANCE_UPDATE_SUCCESS';
export const COMPANY_INSURANCE_UPDATE_FAIL = 'COMPANY_INSURANCE_UPDATE_FAIL';

export const COMPANY_SAFETY_REQUEST = 'COMPANY_SAFETY_REQUEST';
export const COMPANY_SAFETY_SUCCESS = 'COMPANY_SAFETY_SUCCESS';
export const COMPANY_SAFETY_FAIL = 'COMPANY_SAFETY_FAIL';

export const COMPANY_SAFETY_UPDATE_REQUEST = 'COMPANY_SAFETY_UPDATE_REQUEST';
export const COMPANY_SAFETY_UPDATE_SUCCESS = 'COMPANY_SAFETY_UPDATE_SUCCESS';
export const COMPANY_SAFETY_UPDATE_FAIL = 'COMPANY_SAFETY_UPDATE_FAIL';

export const COMPANY_FINANCE_REQUEST = 'COMPANY_FINANCE_REQUEST';
export const COMPANY_FINANCE_SUCCESS = 'COMPANY_FINANCE_SUCCESS';
export const COMPANY_FINANCE_FAIL = 'COMPANY_FINANCE_FAIL';

export const COMPANY_FINANCE_UPDATE_REQUEST = 'COMPANY_FINANCE_UPDATE_REQUEST';
export const COMPANY_FINANCE_UPDATE_SUCCESS = 'COMPANY_FINANCE_UPDATE_SUCCESS';
export const COMPANY_FINANCE_UPDATE_FAIL = 'COMPANY_FINANCE_UPDATE_FAIL';

export const COMPANY_SUPPLIER_REQUEST = 'COMPANY_SUPPLIER_REQUEST';
export const COMPANY_SUPPLIER_SUCCESS = 'COMPANY_SUPPLIER_SUCCESS';
export const COMPANY_SUPPLIER_FAIL = 'COMPANY_SUPPLIER_FAIL';

export const COMPANY_SUPPLIER_UPDATE_REQUEST = 'COMPANY_SUPPLIER_UPDATE_REQUEST';
export const COMPANY_SUPPLIER_UPDATE_SUCCESS = 'COMPANY_SUPPLIER_UPDATE_SUCCESS';
export const COMPANY_SUPPLIER_UPDATE_FAIL = 'COMPANY_SUPPLIER_UPDATE_FAIL';

export const COMPANY_LEGAL_REQUEST = 'COMPANY_LEGAL_REQUEST';
export const COMPANY_LEGAL_SUCCESS = 'COMPANY_LEGAL_SUCCESS';
export const COMPANY_LEGAL_FAIL = 'COMPANY_LEGAL_FAIL';

export const COMPANY_LEGAL_UPDATE_REQUEST = 'COMPANY_LEGAL_UPDATE_REQUEST';
export const COMPANY_LEGAL_UPDATE_SUCCESS = 'COMPANY_LEGAL_UPDATE_SUCCESS';
export const COMPANY_LEGAL_UPDATE_FAIL = 'COMPANY_LEGAL_UPDATE_FAIL';

export const COMPANY_SHIPPING_REQUEST = 'COMPANY_SHIPPING_REQUEST';
export const COMPANY_SHIPPING_SUCCESS = 'COMPANY_SHIPPING_SUCCESS';
export const COMPANY_SHIPPING_FAIL = 'COMPANY_SHIPPING_FAIL';

export const COMPANY_SHIPPING_UPDATE_REQUEST = 'COMPANY_SHIPPING_UPDATE_REQUEST';
export const COMPANY_SHIPPING_UPDATE_SUCCESS = 'COMPANY_SHIPPING_UPDATE_SUCCESS';
export const COMPANY_SHIPPING_UPDATE_FAIL = 'COMPANY_SHIPPING_UPDATE_FAIL';


export const PLANIFY_COMPANY_DETAILS_REQUEST = 'PLANIFY_COMPANY_DETAILS_REQUEST';
export const PLANIFY_COMPANY_DETAILS_SUCCESS= 'PLANIFY_COMPANY_DETAILS_SUCCESS';
export const PLANIFY_COMPANY_DETAILS_FAIL = 'PLANIFY_COMPANY_DETAILS_FAIL';

export const UPDATE_PLANIFY_COMPANY_REQUEST = 'UPDATE_PLANIFY_COMPANY_REQUEST';
export const UPDATE_PLANIFY_COMPANY_SUCCESS= 'UPDATE_PLANIFY_COMPANY_SUCCESS';
export const UPDATE_PLANIFY_COMPANY_FAIL = 'UPDATE_PLANIFY_COMPANY_FAIL';

