import React, { useEffect, useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { getCompanyDetail } from "../../store/actions/users/users.actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import { ShareSocial } from "react-share-social";
import styled from "styled-components";
const customFetcher = async (url) => {
  const response = await fetch(
    `https://linkpreview-ea64d038b2ff.herokuapp.com/v2?url=${url}`
  );
  const json = await response.json();
  return json.metadata;
};
const StyledLinkPreview = styled(LinkPreview)`
  .Container {
    width: 158px;
    height: 341px;
  }
  .Image {
    width: 50%;
  }
`;

const GCPlanifySetup = () => {
  const initialState = {
    pre_qualification_url: "",
    plan_room_url: "",
    working_region: [], // Example for a multi-select field, initialize as an empty array
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    website: "",
    is_add_itbs: false, // Example for a checkbox, initialize as needed
    is_talent_request: false,
    is_davis_bacon: false,
    is_union: false,
    // Add other form fields here and initialize them as needed
  };
  const [state, setState] = useState(initialState);
  const { user } = useSelector((state) => state.auth);
  const { loading, companyDetail } = useSelector(
    (state) => state.companyDetail
  );
  let params = useParams();
  let { company_slug } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      // dispatch(getCompanyDetail(user?.company_slug));
    } else if (company_slug) {
      // dispatch(getCompanyDetail(company_slug));
    }
  }, [company_slug, dispatch, user]);
  // Update the component state when companyDetail changes.
  useEffect(() => {
    if (companyDetail?.data) {
      setState(companyDetail?.data);
    }
  }, [companyDetail?.data]);

  const handleSwitchInput = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
  };
  const displayRegion = () => {
    return state?.working_region?.map((region) => {
      return (
        <span
          key={region.name}
          className="border-2 p-3  d-inline-block
           fw-bolder m-2 rounded-4 text-capitalize"
          style={{ backgroundColor: "#0d6efd", color: "white" }}
        >
          {region.name}
        </span>
      );
    });
  };

  return (
    <Container className="my-3">
      <Row className="justify-content-between">
        <Col md={6} className="mb-5">
          <h1 className="text-start my-5 display-5 fw-semibold text-capitalize">
            {state.company_name}
          </h1>
          {/*{user && (*/}
          {/*  <ShareSocial*/}
          {/*    url={`https://gcplanify.app.ganarpro.com/company/${user?.company_slug}`}*/}
          {/*    socialTypes={["facebook", "twitter", "reddit", "linkedin"]}*/}
          {/*  />*/}
          {/*)}*/}

          <Row className="">
            <Col className="" md={12}>
              <h3 className="fw-bold mb-4 text-center">Working Region</h3>
              {displayRegion()}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={5} lg={3} className="mx-auto text-center">
          <Row>
            <Col md={12} className="col-4">
              <h3 className="fw-bold">Prequalification application</h3>
            </Col>
            <Col md={12} className="col-6 col-sm-6 text-center">
              {state.pre_qualification_url && (
                <StyledLinkPreview
                  url={state.pre_qualification_url}
                  fetcher={customFetcher}
                  className="w-100"
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col md={2} lg={3}>
          <Row>
            <Col md={12} className="col-4">
              <h3 className="fw-bold">Plan room</h3>
            </Col>
            <Col className="col-6 col-sm-12 text-center">
              {state.plan_room_url && (
                <LinkPreview
                  url={state.plan_room_url}
                  fetcher={customFetcher}
                  className="w-100"
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col md={4} lg={3}>
          <h3 className="fw-bold mt-xs-4">Socials</h3>
          <div className="form-group row my-3">
            <label className="col-sm-4 col-form-label  fw-bold col-4">
              LinkedIn
            </label>
            <div className="col-sm-7 col-6">
              {state.linkedin && (
                <LinkPreview
                  url={state.linkedin}
                  fetcher={customFetcher}
                  className="w-100"
                />
              )}
            </div>
          </div>
          <div className="form-group row my-4">
            <label className="col-sm-4 col-form-label  fw-bold col-4">
              Facebook
            </label>
            <div className="col-sm-7 col-6">
              {state.facebook && (
                <LinkPreview
                  url={state.facebook}
                  fetcher={customFetcher}
                  className="w-100"
                />
              )}
            </div>
          </div>
          <div className="form-group row my-4">
            <label className="col-sm-4 col-form-label  fw-bold col-4">
              Twitter
            </label>
            <div className="col-sm-7 col-6">
              {state.twitter && (
                <LinkPreview
                  url={state.twitter}
                  fetcher={customFetcher}
                  className="w-100"
                />
              )}
            </div>
          </div>
          <div className="form-group row my-4">
            <label className="col-sm-4 col-form-label  fw-bold col-4">
              Instagram
            </label>
            <div className="col-sm-7 col-6">
              {state.instagram && (
                <LinkPreview
                  url={state.instagram}
                  fetcher={customFetcher}
                  className="w-100"
                />
              )}
            </div>
          </div>

          <div className="form-group row my-4">
            <label className="col-sm-4 col-form-label fw-bold col-4">
              Youtube
            </label>
            <div className="col-sm-7 col-6 ">
              {state.youtube && (
                <LinkPreview
                  url={state.youtube}
                  fetcher={customFetcher}
                  className="w-100"
                />
              )}
            </div>
          </div>
        </Col>
        <Col md={2} lg={3}>
          <Row>
            <Col md={8} className="col-4">
              <h3 className="fw-bold">Website</h3>
            </Col>
            <Col className="col-6 col-sm-8 text-center">
              {state.linkedin && (
                <LinkPreview
                  url={state.linkedin}
                  fetcher={customFetcher}
                  className="w-100"
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Row className="my-3 mx-3">
            {(state.is_add_itbs || state.is_talent_request) && (
              <Col>
                <h2 className="fw-bold my-3 text-start"> Service request</h2>
                {state.is_add_itbs && (
                  <Form.Group
                    as={Col}
                    md={7}
                    lg={7}
                    className="mb-3 checkbox m-0 toggle-switch"
                    controlId="price_per_sqft"
                  >
                    <Form.Check
                      type="switch"
                      name="is_add_itbs"
                      label="I want to Add ITBS, post bid opportunities"
                      className="mb-4"
                      disabled
                      checked={state.is_add_itbs}
                      onChange={handleSwitchInput || false}
                    />
                  </Form.Group>
                )}

                {state.is_talent_request && (
                  <Form.Group
                    as={Col}
                    md={7}
                    lg={5}
                    className="mb-3 checkbox m-0 toggle-switch"
                    controlId="useLivingUnitPricing"
                  >
                    <Form.Check
                      type="switch"
                      name="is_talent_request"
                      label="Talent recruitment request"
                      className="mb-1"
                      disabled
                      checked={state.is_talent_request}
                      onChange={handleSwitchInput || false}
                    />
                  </Form.Group>
                )}
              </Col>
            )}
            {(state.is_davis_bacon || state.is_union) && (
              <Col>
                <h2 className="text-start my-3 fw-bold">
                  Public work involvement
                </h2>
                {state.is_davis_bacon && (
                  <Form.Group
                    as={Col}
                    md={7}
                    lg={7}
                    className="mb-3 checkbox m-0 toggle-switch"
                    controlId="useNumberoFDays"
                  >
                    <Form.Check
                      type="switch"
                      name="is_davis_bacon"
                      label="Davis Bacon"
                      className="mb-4"
                      disabled
                      checked={state.is_davis_bacon}
                      onChange={handleSwitchInput || false}
                    />
                  </Form.Group>
                )}

                {state.is_union && (
                  <Form.Group
                    as={Col}
                    md={7}
                    lg={5}
                    className="mb-3 checkbox m-0 toggle-switch"
                    controlId="useNumberoFDays"
                  >
                    <Form.Check
                      type="switch"
                      name="is_union"
                      label="Union"
                      className="mb-4"
                      disabled
                      checked={state.is_union || false}
                      onChange={handleSwitchInput}
                    />
                  </Form.Group>
                )}
              </Col>
            )}
          </Row>
        </Col>
        <Row className="mb-5 justify-content-end">
          <Col className="text-start col-1">
            <Link to="gcplanify-setup">
              <button className="btn btn-primary p-3 rounded-4">
                <span className="fw-bold heading-4">Edit</span>
              </button>
            </Link>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default GCPlanifySetup;
